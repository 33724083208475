<template>
    <section>
        <div
            id="modal"
            class="uk-flex-top"
            uk-modal
            esc-close="false"
            bg-close="false"
        >
            <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <button
                    class="uk-modal-close-default"
                    type="button"
                    @click="hideModal"
                    uk-close
                ></button>

                <div
                    :id="selected._id"
                    class="uk-container uk-container-expand uk-margin-medium print-setting"
                >
                    <div>
                        <h4
                            style="
                                text-align: center;
                                margin-bottom: 30px;
                                font-weight: bold;
                            "
                        >
                            SURAT PERINTAH KERJA LEMBUR (SPKL)
                        </h4>
                        <p>Diperintahkan kepada :</p>
                        <table>
                            <tbody>
                                <tr>
                                    <td style="width: 30%">Nama</td>
                                    <td style="width: 5%">:</td>
                                    <td style="width: max-content">
                                        {{ selected.fullname }}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 30%">Job Role</td>
                                    <td style="width: 5%">:</td>
                                    <td style="width: max-content">
                                        {{ selected.role }}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 30%">Office</td>
                                    <td style="width: 5%">:</td>
                                    <td style="width: max-content">
                                        {{ selected.office_name }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>Untuk melaksanakan kerja lembur pada :</p>
                        <table>
                            <tbody>
                                <tr>
                                    <td style="width: 30%">Hari</td>
                                    <td style="width: 5%">:</td>
                                    <td style="width: max-content">
                                        {{
                                            dateTime
                                                .fromISO(selected.overtime_date)
                                                .toFormat("cccc")
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 30%">Tanggal</td>
                                    <td style="width: 5%">:</td>
                                    <td style="width: max-content">
                                        {{
                                            dateTime
                                                .fromISO(selected.overtime_date)
                                                .toLocaleString(
                                                    dateTime.DATE_FULL
                                                )
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 30%">Jam Lembur</td>
                                    <td style="width: 5%">:</td>
                                    <td style="width: max-content">
                                        {{
                                            dateTime
                                                .fromISO(selected.overtime_date)
                                                .toLocaleString(
                                                    dateTime.TIME_24_SIMPLE
                                                )
                                        }}
                                        -
                                        {{
                                            dateTime
                                                .fromISO(selected.overtime_date)
                                                .plus({
                                                    hours: selected.overtime_duration
                                                })
                                                .toLocaleString(
                                                    dateTime.TIME_24_SIMPLE
                                                )
                                        }}
                                        WIB
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 30%">Keterangan</td>
                                    <td style="width: 5%">:</td>
                                    <td style="width: max-content">
                                        {{ selected.overtime_reason }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            Demikian Surat Perintah Kerja Lembur ini dibuat,
                            untuk dipergunakan sebagaimana mestinya.
                        </p>
                        <table style="font-weight: bold; width: 100%">
                            <tbody>
                                <tr>
                                    <td style="width: 50%; text-align: left">
                                        SPV
                                    </td>
                                    <td style="width: 50%; text-align: right">
                                        Yang Melaksanakan Lembur
                                    </td>
                                </tr>
                                <tr style="height: 100px"></tr>
                                <tr style="margin-top: 80px">
                                    <td style="width: 50%; text-align: left">
                                        {{ selected.spv_name }}
                                    </td>
                                    <td style="width: 50%; text-align: right">
                                        {{ selected.fullname }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="uk-flex uk-flex-center">
                    <button
                        class="uk-button uk-button-primary uk-border-rounded"
                        @click="print"
                    >
                        Download
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { DateTime } from "luxon";

export default {
    props: {
        selected: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            dateTime: DateTime
        };
    },
    mounted() {
        window.UIkit.modal("#modal").show();
    },
    methods: {
        hideModal() {
            window.UIkit.modal("#modal").hide();
            this.$emit("hideModal");
        },
        print() {
            document.title = `SPKL ${this.selected.fullname} ${this.dateTime
                .fromISO(this.selected.overtime_date)
                .toLocaleString(this.dateTime.DATE_FULL)}`;
            const elem = this.selected._id;

            var mywindow = window.open("", "PRINT SPKL");
            mywindow.document.write(`<html><head>`);
            mywindow.document.write(`<title>${document.title}</title>`);
            mywindow.document.write("</head><body>");
            mywindow.document.write(document.getElementById(elem).innerHTML);
            mywindow.document.write("</body></html>");

            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/

            mywindow.print();
            mywindow.close();

            return true;
        }
    }
};
</script>

<style scoped>
* {
    -webkit-print-color-adjust: exact !important;
    /* Chrome, Safari */
    print-color-adjust: exact !important;
    /*Firefox*/
}

@media print {
    @page {
        margin: 0px;
        padding: 50px;
        size: A4 portrait;
    }

    .print-setting {
        padding-top: 100px;
        padding-bottom: 100px;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }
}
</style>
